import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Privacy = props => (
  <Layout>
    <Helmet>
      <title>Privacy</title>
      <meta name="description" content="Privacy" />
    </Helmet>

    <div id="main">
      <div id="privacy" className="privacy">
        <h1>Privacy</h1>
        <p>
          Mix Rider is committed to protecting your privacy. This Privacy Policy
          outlines the types of information collected and how it is used in the
          Mix Rider App. By using the App, you agree to the practices described
          in this policy.
        </p>
        <h3>Information Collection:</h3>
        <p>
          The App does not collect any personal or sensitive data from its
          users. We do not gather, store, or process any information that could
          identify you or your device. Additionally, there is no option to
          create an account, upload personal data, or provide any information
          within the App.
        </p>
        <h3>Automatic Data Collection:</h3>
        <p>
          The App does not use any tracking mechanisms, cookies, or other
          technologies to monitor user activity. We do not collect any
          information about your interactions with the App or your device.
        </p>
        <h3>Third-Party Services:</h3>
        <p>
          The App does not integrate with any third-party services that would
          collect personal or usage data. We do not share any information with
          third-party companies, advertisers, or analytics providers.
        </p>
        <h3>Data Security:</h3>
        <p>
          We take data security seriously and have implemented reasonable
          measures to protect the App and its users from unauthorized access,
          alteration, or disclosure. Despite our efforts, please be aware that
          no method of data transmission over the internet or method of
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </p>
        <h3>Changes to the Privacy Policy:</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be effective immediately upon posting the revised version of the
          policy within the App. Please review this policy periodically to stay
          informed about how we handle your information.
        </p>
        <p>
          By using the App, you agree to the terms outlined in this Privacy
          Policy. If you do not agree with this policy, please refrain from
          using the App.
        </p>
      </div>
    </div>
  </Layout>
)

export default Privacy
